.brandLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.menuText {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.53;
    color: #293143;
}

.sideMenuTitle {
    padding: 8px 24px;
    margin-top: 22px;
}

.ant-menu-item-selected a {
    color: #ffffff;
}

.ant-menu-sub.ant-menu-inline {
    background: #ffdd00;
}

.menuText .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #ffffff;
}
