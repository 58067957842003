.branchTestListInput {
  display: flex;
}
.branchTestListInput .moveItemButtons {
  padding: 0 40px; 
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.branchTestListInput .moveItemButtons button {
  border-radius: 50%;
  width: 58px;
  height: 58px;
  border: 0px;
  background: #d8d8d8;
  margin-bottom: 16px;
  color: #000;
}
.branchTestListInput .moveItemButtons button:disabled {
  opacity: 0.3;
}
.branchTestListInput .testAvailableWrapper,
.branchTestListInput .testSelectedWrapper {
  width: 363px;
}