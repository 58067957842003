.settingsManagementWrapper {
  width: 100%;
  height: auto;
  background-color: #f8f8fb;
  padding: 0 25px;
}
.settingsManagementWrapper .breadCrumbsComponentWrapper {
  padding: 31px 0 20px;
}
.settingsDetailWrapper {
  padding: 25px 40px;
  background-color: #fff;
  padding-bottom: 106px;
}
.settingsDetailContent {
  width: 500px;
}
.settingsDetailContent .settingsDetailHeader {
  display: flex;
  margin-bottom: 20px;
}
.settingsDetailContent .settingsDetailHeader .settingsDetailHeaderText {
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  letter-spacing: -0.53px;
}
.settingsOptionCardBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;
}
.settingsOptionCardBody .settingsOptionCardTitle {
  font-weight: bold;
  margin-left: 26px;
  letter-spacing: -0.53px;
}
.settingsOptionCardBody .settingsOptionCardLink {
  font-weight: 500;
  color: #3d7dc3;
  width: fit-content;
  letter-spacing: -0.53px;
}
.settingsSwitchButton {
  position: relative;
  margin: auto 0;
}
.toggleEnable1 {
  width: 21px;
  height: 12px;
  margin: 0 6px 0 0;
  background-color: #98c8e6;
  float: left;
  z-index: 2;
  position: relative;
  border-radius: 30px;
}
.toggleEnable2 {
  width: 12px;
  height: 12px;
  margin: 0 0 0 8px;
  background-color: #0063a0;
  position: relative;
  z-index: 3;
  border-radius: 30px;
}
.toggleDisable1 {
  width: 21px;
  height: 12px;
  margin: 0 6px 0 0;
  background-color: #ccc;
  float: left;
  z-index: 2;
  position: relative;
  border-radius: 30px;
}
.toggleDisable2 {
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
  background-color: #8f8d8d;
  position: relative;
  z-index: 3;
  border-radius: 30px;
}
.pointer {
  cursor: pointer;
}
.disabledText {
  opacity: 0.5;
  cursor: default;
}
.settingsViewAction {
  width: 700px;
  display: flex;
  margin-top: 14px;
}

.settingsViewAction .ant-btn {
  width: 170px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
}
