/* This used to override body style from tinymce skin */
body {
    margin: 0;
    font-family: 'Poppins';
}

.promoFormViewWrapper {
    padding: 11px 9px;
}

.promoFormViewWrapper .ant-form-vertical .ant-form-item {
    width: 700px;
}

.promoFormViewWrapper .ant-input[disabled] {
    color: #495057;
    background-color: white;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.promoFormViewAction {
    width: 700px;
    display: flex;
    margin-top: 14px;
}

.promoFormViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.listItemEllipsis p {
    position: relative;
    overflow: hidden;
    display: inline-block;
    word-wrap: break-word;
}

.promoFormViewWrapper .tableCardWrapper .ant-card-body {
    padding: 0 0 86px 0;
}

.promoFormViewWrapper .tableCardWrapper .ant-table-tbody > tr > td {
    vertical-align: top;
}

.promoFormViewWrapper .tableCardWrapper .ant-table-tbody > tr > td:last-child {
    vertical-align: middle;
}

.promoFormViewWrapper .tableCardWrapper .ant-table-row {
    box-shadow: none;
}

.promoFormViewWrapper .contractListWrapper .contractListHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 200px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.61px;
    color: #0063a0;
    border-bottom: solid 2px #0063a0;
}
.promoFormViewWrapper .contractListWrapper .tableCardWrapper .rowWhite {
    box-shadow: none;
}
