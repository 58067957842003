.createPasswordViewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
}
.createPasswordViewWrapper .ant-form-item {
  margin-bottom: 0;
}
.createPasswordViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.createPasswordViewWrapper .ant-form-item-label {
  padding: 0 0 10px;
}
.createPasswordViewWrapper .ant-input {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 9px 11px;
  color: #495057;
}
.createPasswordViewWrapper .ant-input-affix-wrapper {
  padding: 9px 11px;
}
.createPasswordViewWrapper .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
}
.createPasswordViewWrapper .ant-form-item-explain, .ant-form-item-extra {
  margin-top: 5px;
  font-size: 12px;
  transition: none;
  color: #e71c1c;
}
.createPasswordViewWrapper .ant-form-item-control {
  width: 100%;
  max-width: 100%;
  min-height: 75px;
}
.createPasswordViewWrapper .ant-form {
  width: 500px;
  background-color: #ffffff;
}
.createPasswordViewHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffdd00;
  width: 100%;
  height: 80px;
  margin-bottom: 35px;
}
.createPasswordViewHeader img {
  width: 98px;
  height: 35px;
}
.createPasswordViewFormContent {
  width: 100%;
  padding: 0 50px;
}
.createPasswordViewFormTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #495057;
  margin-bottom: 5px;
}
.createPasswordViewFormDesc {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #74788d;
  margin-bottom: 35px;
}
.createPasswordViewButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 63px;
}
.blue-tooltip.password-tooltip .ant-tooltip-inner {
  width: 280px;
}
.createPasswordViewWrapper .ant-form-item.newPasswordFormItem {
  position: relative;
}
.createPasswordViewWrapper .tooltipTriggerBtn {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
}