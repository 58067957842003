@import url(https://fonts.googleapis.com/css2?family=Poppins);
.blue-tooltip .ant-tooltip-inner {
  background: #f1faff;
  color: #0063a0;
  border: 1px solid #0063a0;
  border-radius: 4px;
  z-index: 10;
}
.blue-tooltip .ant-tooltip-arrow {
  width: 17.071068px;
  height: 13.071068px;
  background: transparent;
}
.blue-tooltip .ant-tooltip-arrow-content {
  background: #f1faff;
  border: 1px solid #0063a0;
  width: 13px;
  height: 13px;
}
.blue-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -4.071068px;
}

.blue-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -4.071068px;
}
.app-table-rows thead th {
    background-color: #f8f9fa;
    color: #495057;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.app-table-rows tr {
    color: #495057;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.app-table-rows tr:nth-child(2n) {
    background-color: #f1f9ff;
}

.app-pagination .ant-pagination-item {
    border: none;
}

.ant-form-item-label > label {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.ant-input {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.appActionGroupPopOver {
    z-index: 1;
}

.appActionGroupPopOver .ant-popover-arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    right: 61px !important;
}

.appActionGroupPopOver .ant-popover-inner-content {
    padding: 0;
}

.appPopOverContent {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    box-shadow: 0 0 10px 0 rgba(162, 162, 162, 0.5);
}

.appPopOverContent .appPopOverItem {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: center;
            align-content: center;
    border-bottom: solid 1px #bfbfbf;
    padding: 10px 16px 10px 18px;
    cursor: pointer;
}

.appPopOverContent .appPopOverItem img {
    margin-right: 8px;
    width: 16px;
}

.appPopOverContent .appPopOverItem span {
    color: #3d7dc3;
}

.appPopOverContent .appPopOverItem.red span {
    color: #c33d3d;
}

.appPopOverContent .appPopOverItem.red img {
    width: 13px;
}
.appPopOverContent .appPopOverItem.edit img {
    width: 14px;
}
.appPopOverContent .appPopOverItem.sendLink img {
    width: 14px;
}
.changePasswordViewWrapper {
    border-radius: 50px;
}

.changePasswordViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.changePasswordViewTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: 'normal';
    font-style: 'normal';
    line-height: 'normal';
    letter-spacing: -0.65;
    color: '#495057';
    margin-bottom: 23px;
}

.changePasswordViewAction {
    display: -webkit-flex;
    display: flex;
    margin-top: 50px;
}

.changePasswordViewAction .ant-btn {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.changePasswordViewAction .ant-btn-primary {
    width: 170px;
}

.loadingWithMaskWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #000000;
}
.loadingWithMaskIcon {
    width: 56px;
    height: 56px;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
}
.tableLoadingWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.tableLoadingIcon {
    width: 42px;
    height: 42px;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
}

.ovalButtonWrapper {
    width: 170px;
    font-size: 13px;
    letter-spacing: -0.53px;
    padding: 0;
}
.ovalButtonWrapper span {
    vertical-align: middle;
}
.ovalButtonWrapper .anticon-plus {
    font-size: 14px;
    margin-right: 5px;
}
.ovalButtonWrapper .ovalButtonImg {
    margin-right: 13px;
}
.ovalButtonWrapper.ovalButtonRed {
    background: #a00000;
    border-color: #a00000;
}
.ovalButtonWrapper .ovalButtonDownload {
    width: 20px;
    margin-right: 5px;
}
.ovalButtonWrapper.ovalButtonNeutral {
    border-color: #0063a0;
    color: #0063a0;
}
.ovalButtonWrapper.ovalButtonWhite {
    border-color: #0063a0;
    color: #0063a0;
    background: #fff;
}
.modalWithLoadingWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.7;
    background-color: #000000;
}
.modalWithLoadingIcon {
    width: 42px;
    height: 42px;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
}
.loadingWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.loadingContainerWrapper .ant-modal-body {
    padding: 90px 0;
}
.loadingWrapper img {
    margin-bottom: 24px;
}
.loadingWrapper .loadingMsg {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.73px;
    text-align: center;
    color: #495057;
}
.loadingWrapper .loadingMsg span{
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.61px;
    text-align: center;
    color: #495057;
}
.ModalConfirmationWrapper .ant-modal-header{
    padding: 15px 22px 5px;
    border-bottom: 0;
}
.ModalConfirmationWrapper .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.65px;
    color: #495057;
}
.ModalConfirmationWrapper .ant-modal-close-x{
    width: 20px;
    height: 20px;
    line-height: 20px;
}
.ModalConfirmationWrapper .ant-modal-close {
    top: 15px;
    right: 13px;
}
.ModalConfirmationWrapper .ant-modal-body {
    padding: 0 22px 36px;
}
.ModalConfirmationWrapper .ant-modal-body > div {
    width: 205px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.61px;
    color: #495057;
}
.ModalConfirmationWrapper .ant-modal-footer {
    border-top: 0;
    padding: 0 22px 20px;
}
.ModalConfirmationWrapper .ovalButtonWrapper {
    width: 100%;
}
.TypeDeleteConfirmation .ant-modal-body {
    padding: 0 22px 57px;
}
.TypeDeleteConfirmation .ant-modal-body > div {
    width: 160px;
}
.ModalViewImageWrapper .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}
.ModalViewImageWrapper .ant-modal-close {
    top: 21px;
    right: 21px;
}
.ModalViewImageWrapper .ant-modal-close-x {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
}
.ModalViewImageWrapper .ant-modal-close-x img {
    width: 24px;
    height: 24px;
}
.ModalViewImageWrapper .ant-modal-body {
    padding: 21px;
    padding-top: 64px;
}
.ModalViewImageWrapper .viewImg {
    width: 546px;
}
.ModalErrorWrapper .ant-modal-body {
    padding: 0;
}
.ModalErrorWrapper .ant-modal-footer {
    border-top: 0;
    padding: 0;
}
.ModalErrorWrapper .handleErrorImg {
    width: 90px;
    height: 90px;
}
.ModalErrorWrapper .handleErrorContent {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.ModalErrorWrapper .title {
    height: 25px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.73px;
    color: #495057;
}
.ModalErrorWrapper .description {
    height: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.57px;
    color: #495057;
}
.ModalErrorWrapper .ant-modal-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.ModalErrorWrapper .ovalButtonWrapper {
    width: 238px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #ffffff;
}
.TypePageNotAllowed .ovalButtonWrapper {
    margin-bottom: 27px;
}
.TypePageNotAllowed .handleErrorImg{
    margin-top: 8px;
    margin-bottom: 4px;
}
.TypePageNotAllowed .description {
    margin-top: 4px;
    margin-bottom: 23px;
}
.TypeSessionExpired .handleErrorImg{
    margin-top: 21px;
    margin-bottom: 0;
}
.TypeSessionExpired .ovalButtonWrapper {
    margin-bottom: 14px;
}
.TypeSessionExpired .description {
    margin-top: 4px;
    margin-bottom: 37px;
}
.TypeTimeOut .handleErrorImg{
    margin-top: 12px;
    margin-bottom: 9px;
}
.TypeTimeOut .description {
    height: 36px;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 19px;
}
.TypeTimeOut .ovalButtonWrapper {
    margin-bottom: 14px;
}
.TypePageNotFound .handleErrorImg{
    margin-top: 14px;
    margin-bottom: 1px;
}
.TypePageNotFound .description {
    height: 20px;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 24px;
}
.TypePageNotFound .ovalButtonWrapper {
    margin-bottom: 23px;
}
.promoWrapper.TypeDeleteConfirmation .ant-modal-body > div {
    width: 230px;
}
.ModalProdia {
    border-radius: 5px;
}
.ModalProdia .ant-modal-header {
    border: 0;
    background: #ffdd00;
    padding-top: 22px;
    padding-bottom: 22px;
    border-radius: 5px 5px 0px 0px;
}
.ModalProdia .ant-modal-content {
    border-radius: 5px;
}
.ModalProdia .ant-modal-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.ModalProdia .ant-modal-body {
    padding: 24px 32px;
}
.ModalProdia .ant-modal-footer {
    border: 0;
}
.ModalProdia .modalContent {
    color: #495057;
}
.ModalProdia .ant-modal-footer {
    padding-bottom: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.ModalProdia .contentTitle {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 14px;
}   
.ModalProdia .contentText {
    text-align: center;
    margin-bottom: 0;
}
.ModalProdia .sendActivationContent .pinText {
    color: #0063a0;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    margin-bottom: 0;
}
.ModalProdia .sendActivationContent .buttonCopyClipboardWrapper {
    position: relative;
}
.ModalProdia .sendActivationContent .buttonCopyClipboard {
    background: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    bottom: 0px;
    cursor: pointer;
}

.alertComponentWrapper {
    position: fixed;
    width: 500px;
    top: 0;
    left: 50%;
    margin-left: -250px;
    padding: 12.5px 38px 12.5px 20px !important;
    box-shadow: 0 0 10px 0 rgba(121, 121, 121, 0.5);
    z-index: 9999;
}
.alertComponentWrapper.ant-alert-error {
    background: #c27f7f;
    border: 1px solid #c27f7f;
}
.alertComponentWrapper.ant-alert-success {
    background: #84c27f;
    border: 1px solid #84c27f;
}
.alertComponentWrapper .ant-alert-message {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    letter-spacing: -0.61px;
}
.alertComponentWrapper .ant-alert-close-text {
    font-size: 15px;
    font-weight: 600;
    color: #6b4141;
    line-height: 20px;
    letter-spacing: -0.61px;
}
.alertComponentWrapper.ant-alert-success .ant-alert-close-text {
    color: #446b41;
}
.alertComponentWrapper.ant-alert.ant-alert-no-icon .ant-alert-close-icon {
    height: 20px;
    top: 50%;
}

.brandLogo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 70px;
}

.menuText {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.53;
    color: #293143;
}

.sideMenuTitle {
    padding: 8px 24px;
    margin-top: 22px;
}

.ant-menu-item-selected a {
    color: #ffffff;
}

.ant-menu-sub.ant-menu-inline {
    background: #ffdd00;
}

.menuText .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #ffffff;
}

.breadCrumbsComponentWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 31px 25px 20px;
}
.breadCrumbsComponentWrapper .ant-breadcrumb {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    -webkit-align-self: center;
            align-self: center;
}
.breadCrumbsComponentWrapper .ant-breadcrumb a {
    color: #0063a0;
}
.breadCrumbsComponentWrapper .ant-breadcrumb a:hover {
    color: #0063a0;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
.breadCrumbsComponentWrapper .ant-breadcrumb-separator {
    margin: 0 12px;
    color: #000;
}
.breadCrumbsComponentWrapper .breadCrumbsDisable {
    color: #707070;
}
.breadCrumbsComponentWrapper .breadCrumbsButtonsWrapper button {
    margin-left: 15px;
}
.loginViewWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #f5f5f5;
    width: 100vw;
    height: 100vh;
}
.loginViewWrapper .ant-form-item {
    margin-bottom: 0;
}
.loginViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.loginViewWrapper .ant-form-item-label {
    padding: 0 0 10px;
}
.loginViewWrapper .ant-input {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    padding: 9px 11px;
    color: #495057;
}
.loginViewWrapper .ant-input-affix-wrapper {
    padding: 9px 11px;
}
.loginViewWrapper .ant-form-item-label > label {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    color: #495057;
}
.loginViewWrapper .ant-form-item-explain, .ant-form-item-extra {
    margin-top: 5px;
    font-size: 12px;
    transition: none;
    color: #e71c1c;
}
.loginViewWrapper .ant-form-item-control {
    width: 100%;
    max-width: 100%;
    min-height: 75px;
}
.loginViewWrapper .ant-form {
    width: 500px;
    background-color: #ffffff;
}
.loginViewHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #ffdd00;
    width: 100%;
    height: 80px;
    margin-bottom: 35px;
}
.loginViewHeader img {
    width: 98px;
    height: 35px;
}
.loginViewFormContent {
    width: 100%;
    padding: 0 50px;
}
.loginViewFormTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #495057;
    margin-bottom: 5px;
}
.loginViewFormDesc {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #74788d;
    margin-bottom: 35px;
}
.loginViewButtonWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 63px;
}
.loginViewButtonWrapper .activation {
    color: #74788d;
}
.loginViewButtonWrapper .activation .activationLink {
    font-weight: bold;
    color: #0063a0;
}

.accountActivationViewWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
}
.accountActivationViewWrapper .ant-form-item {
  margin-bottom: 0;
}
.accountActivationViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.accountActivationViewWrapper .ant-form-item-label {
  padding: 0 0 10px;
}
.accountActivationViewWrapper .ant-input {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 9px 11px;
  color: #495057;
}
.accountActivationViewWrapper .ant-input-affix-wrapper {
  padding: 9px 11px;
}
.accountActivationViewWrapper .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
}
.accountActivationViewWrapper .ant-form-item-explain, .ant-form-item-extra {
  margin-top: 5px;
  font-size: 12px;
  transition: none;
  color: #e71c1c;
}
.accountActivationViewWrapper .ant-form-item-control {
  width: 100%;
  max-width: 100%;
  min-height: 75px;
}
.accountActivationViewWrapper .ant-form {
  width: 500px;
  background-color: #ffffff;
}
.accountActivationViewHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffdd00;
  width: 100%;
  height: 80px;
  margin-bottom: 35px;
}
.accountActivationViewHeader img {
  width: 98px;
  height: 35px;
}
.accountActivationViewFormContent {
  width: 100%;
  padding: 0 50px;
}
.accountActivationViewFormTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #495057;
  margin-bottom: 5px;
}
.accountActivationViewFormDesc {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #74788d;
  margin-bottom: 35px;
}
.activationViewButtonWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-bottom: 63px;
}

/* Custom tooltip styles */

.blue-tooltip.pin-tooltip {
  max-width: 280px;
}
.blue-tooltip .ant-tooltip-inner {
  background: #f1faff;
  color: #0063a0;
  border: 1px solid #0063a0;
  border-radius: 4px;
  z-index: 10;
}
.blue-tooltip .ant-tooltip-arrow {
  width: 17.071068px;
  height: 13.071068px;
  background: transparent;
}
.blue-tooltip .ant-tooltip-arrow-content {
  background: #f1faff;
  border: 1px solid #0063a0;
  width: 13px;
  height: 13px;
}
.blue-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -4.071068px;
}

.blue-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -4.071068px;
}

.tableCardWrapper {
    border-radius: 3px;
    box-shadow: 0 0 10px 0 #f2f3f6;
    background-color: #ffffff;
    border: none;
}
.tableCardWrapper .ant-card-body {
    padding: 18px;
    padding-bottom: 0;
}
.tableCardWrapper .ant-table-thead {
    border: solid 1px #f0f2f6;
}
.tableCardWrapper .ant-table-thead > tr > th {
    border: none;
    background-color: #f8f9fa;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
    padding: 12px 16px;
}
.tableCardWrapper .rowWhite {
    box-shadow: 0 1px 0 0 #dde1e8;
    background-color: #ffffff;
}
.tableCardWrapper .rowBlue {
    box-shadow: 0 1px 0 0 #dde1e8;
    background-color: #f1f9ff;
}
.tableCardWrapper .ant-table-tbody > tr > td {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
    padding: 19px 16px;
}
.tableCardWrapper .ant-table-tbody > tr > td:last-child {
    padding: 0;
}
.tableCardWrapper .ant-table-tbody > tr:last-child > td {
    border: none;
}
.tableCardWrapper .ant-table-tbody > tr:hover > td {
    background-color: transparent;
}
.tableCardWrapper .indexStyle {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
}
.tableCardWrapper .nameStyle {
    color: #495057;
}
.tableCardWrapper a .nameStyle {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #0063a0;
}
.tableCardWrapper a .nameStyle:hover {
    text-decoration: underline;
}
.tablePaginationWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    margin: 27px 0;
}
.tablePaginationWrapper button[aria-label='paging-nav-first'] {
    font-size: 13px;
    min-width: 80px !important;
    margin-right: 5px;
}
.tablePaginationWrapper button[aria-label='paging-nav-last'] {
    font-size: 13px;
    min-width: 80px !important;
    margin-left: 5px;
}
.tablePaginationWrapper .app-pagination li {
    height: 38px;
}

.searchBoxWrapper {
    width: 200px;
}
.searchBoxWrapper .searchBox {
    border-radius: 19px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    padding: 7px 17px;
}
.searchBoxWrapper .searchBox input {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}
.searchBoxWrapper .searchBox input::-webkit-input-placeholder {
    color: #73798e;
}
.searchBoxWrapper .searchBox input:-ms-input-placeholder {
    color: #73798e;
}
.searchBoxWrapper .searchBox input::placeholder {
    color: #73798e;
}
.searchBoxWrapper--bordered .searchBox {
    border: 1px solid #c4c4c4;
}
.searchBoxWrapper--full {
    width: 100%;
}
.searchBoxWrapper .anticon-search {
    color: #73798e;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: inline-block !important;
}
.statusFilterView {
    min-width: 150px !important;
    background-color: white;
    box-shadow: none;
    border-radius: 19px !important;
}
.statusFilterView .ant-select-selector {
    height: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important;
}
.statusFilterView .ant-select-selection-placeholder {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}
.statusFilterView .ant-select-arrow {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    right: 15px;
}
.statusFilterView .ant-select-selection-item {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}
.sortFilterView {
    min-width: 200px !important;
    background-color: white;
    box-shadow: none;
    border-radius: 19px !important;
}
.sortFilterView .ant-select-selector {
    height: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important;
}
.sortFilterView .ant-select-selection-placeholder {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}
.sortFilterView .ant-select-arrow {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    right: 15px;
}
.sortFilterView .ant-select-selection-item {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}
.selectWrapper {
    min-width: 501px;
}

.selectWrapper .ant-select-selector {
    min-height: 40px;
    padding: 0 0 0 6px;
}

.selectWrapper .anticon.anticon-search {
    display: none;
}

.selectWrapper .ant-tag-close-icon {
    display: inline;
}

.selectWrapper .ant-tag {
    border: none;
    margin: 4px 6px 4px 0;
}

.sortOptionWrapper .ant-select-item-option-content {
    line-height: 30px;
}

.selectOptionWrapper .ant-checkbox-wrapper {
    width: 100%;
}

.selectOptionWrapper label.ant-checkbox-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectOptionWrapper span.anticon.anticon-check {
    display: none;
}

.selectFilterWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    min-width: 150px;
    border: none;
    box-shadow: none;
}

.selectFilterWrapper .label {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}

.selectFilterOpenedButton {
    border: none;
    border-radius: 0;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
}

.selectFilterWrapper sup.ant-scroll-number.ant-badge-count {
    background-color: #0073fe;
    margin-left: 7px;
}

.selectFilterMenu {
    width: 250px;
    margin-top: -4px;
    box-shadow: none;
}

.selectFilterMenu .header {
    display: -webkit-flex;
    display: flex;
    margin: 22px 15px 15px 15px;
}

.selectFilterMenu .divider {
    width: auto;
    margin: 7.5px 15px 7.5px 15px;
    border-top: 1px solid #c6c6c6;
}

.selectFilterMenu .content {
    max-height: 194px;
    overflow: scroll;
}

.selectFilterMenu .searchBox {
    border: solid 1px #d0d0d0;
}

.selectFilterMenu .ant-btn-link {
    color: #3d7dc3;
    padding: 0 0 0 23px;
}

.selectFilterMenu .ant-dropdown-menu-item-group-list {
    margin: 0;
}

.selectFilterMenu .ant-dropdown-menu-item-group-title {
    padding: 0;
}

.selectFilterMenu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    display: -webkit-flex;
    display: flex;
    padding: 7.5px 15px 7.5px 15px;
}

.adminManagementWrapper {
    width: 100%;
    height: 100%;
    background-color: #f8f8fb;
    padding: 26px;
}
.adminManagementFilterWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
}
.ant-popover-inner-content {
    padding: 0;
}
.ant-popover-arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    right: 61px !important;
}
.adminManagementDotsWrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}
.adminManagementDots {
    display: -webkit-flex;
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.actionGroupPopOver {
    z-index: 1;
}
.listItemEllipsis p {
    position: relative;
    overflow: hidden;
    display: inline-block;
    word-wrap: break-word;
}

.adminFormViewWrapper {
    padding: 11px 9px;
}

.adminFormViewWrapper .ant-form {
    width: 501px;
}

.adminFormViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.adminEditViewAction {
    display: -webkit-flex;
    display: flex;
    margin-top: 14px;
}

.adminEditViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.adminDetailWrapper {
    width: 100%;
    height: auto;
    background-color: #f8f8fb;
    padding: 0 25px;
}
.adminDetailWrapper .breadCrumbsComponentWrapper {
    padding: 31px 0 20px;
}
.adminDetailContent {
    padding-left: 38px;
    background-color: #fff;
    padding-bottom: 106px;
}
.adminDetailContent .ant-descriptions-header {
    margin-bottom: 0;
}
.adminDetailContent .ant-descriptions-header .ant-descriptions-title {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding-top: 27px;
    padding-bottom: 22px;
    border-bottom: 1px solid #dde1e8;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    color: #495057;
}
.adminDetailContent .ant-descriptions-row .ant-descriptions-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding: 21px 0;
    border-bottom: 1px solid #dde1e8;
}
.adminDetailContent .ant-descriptions-row:nth-child(3) .ant-descriptions-item {
    width: 100%;
    border-bottom: 0;
}
.adminDetailContent .ant-descriptions-row:nth-child(3):after {
    display: -webkit-flex;
    display: flex;
    width: 500px;
    height: 1px;
    background-color: #dde1e8;
    content: '';
}
.adminDetailContent .ant-descriptions-row:nth-child(4) .ant-descriptions-item {
    width: 100%;
    border-bottom: 0;
}
.adminDetailContent .ant-descriptions-row:nth-child(5) .ant-descriptions-item {
    border-top: 1px solid #dde1e8;
}
.adminDetailContent .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.53px;
    color: #495057;
    width: 270px;
    padding-left: 26px;
    -webkit-align-items: center;
            align-items: center;
}
.adminDetailContent .ant-descriptions-item-label::after {
    display: none;
}
.adminDetailContent .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.53px;
    color: #495057;
}
.adminDetailContent .ant-descriptions-item-content > div {
    margin-bottom: 6px;
}
.adminDetailContent .ant-descriptions-item-content > div:last-child {
    margin-bottom: 0px;
}
.adminDetailContent .listItemIndex {
    margin-right: 15px;
}

.adminDetailContent .activationPINWrapper {
    display: inline-block;
    position: relative;
}

.adminDetailContent .activationPINRow {
    display: -webkit-flex;
    display: flex; 
    -webkit-align-items: center; 
            align-items: center;
}

.adminDetailContent .activationPINRow .iconButton {
    padding-left: 10px; 
    padding-right: 10px; 
}

.adminDetailContent .activationPINRow .iconButton:hover,
.adminDetailContent .activationPINRow .iconButton:focus {
    background: transparent;
}

.adminDetailContent .activationPINRow .iconButton .anticon {
    font-size: 22px;
    color: #0063a0;
}

.adminDetailContent .activationPINMask {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 12px;
}

.adminDetailContent .activationPINMask .ellipsis {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #707070;
    display: inline;
}

.adminDetailContent .branchListContainer {
    overflow-y: auto;
}
.roleManagementWrapper {
    width: 100%;
    height: 100%;
    background-color: #f8f8fb;
    padding: 26px;
}
.roleManagementFilterWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
}
.ant-popover-inner-content {
    padding: 0;
}
.ant-popover-arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    right: 61px !important;
}
.roleManagementDotsWrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}
.roleManagementDots {
    display: -webkit-flex;
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.actionGroupPopOver {
    z-index: 1;
}

.roleDetailWrapper {
    width: 100%;
    height: auto;
    background-color: #f8f8fb;
    padding: 0 25px;
}
.roleDetailWrapper .breadCrumbsComponentWrapper {
    padding: 31px 0 20px;
}
.roleDetailContent {
    padding-left: 38px;
    background-color: #fff;
    padding-bottom: 106px;
}
.roleDetailContent .ant-descriptions-header {
    margin-bottom: 0;
}
.roleDetailContent .ant-descriptions-header .ant-descriptions-title {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding-top: 27px;
    padding-bottom: 22px;
    border-bottom: 1px solid #dde1e8;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    color: #495057;
}
.roleDetailContent .ant-descriptions-row .ant-descriptions-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding: 21px 0;
    border-bottom: 1px solid #dde1e8;
}
.roleDetailContent .ant-descriptions-row:nth-child(2) .ant-descriptions-item {
    width: 100%;
    border-bottom: 0;
}
.roleDetailContent .ant-descriptions-row:nth-child(3) .ant-descriptions-item {
    border-top: 1px solid #dde1e8;
}
.roleDetailContent .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.53px;
    color: #495057;
    width: 270px;
    padding-left: 26px;
}
.roleDetailContent .ant-descriptions-item-label::after {
    display: none;
}
.roleDetailContent .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.53px;
    color: #495057;
}
.roleDetailContent .ant-descriptions-item-content > div {
    margin-bottom: 6px;
}
.roleDetailContent .ant-descriptions-item-content > div:last-child {
    margin-bottom: 0px;
}
.roleDetailContent .permissionItemIndex {
    margin-right: 15px;
}
.ant-form .ant-tree-switcher {
    display: none;
}

.ant-form .ant-tree-title {
    margin-left: 14px;
    padding: 0;
}

.ant-form .ant-tree-indent-unit {
    width: 48px;
}

.ant-form .ant-tree-checkbox {
    margin: 4px 0px 0 0;
}

.roleFormViewWrapper {
    padding: 11px 9px;
}

.roleFormViewWrapper .ant-form {
    width: 501px;
}

.roleFormViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.roleEditViewAction {
    display: -webkit-flex;
    display: flex;
    margin-top: 14px;
}

.roleEditViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.downloadContainerWrapper  .ant-modal-header {
    border-bottom: 0;
    padding: 15px 23px 3px;
}
.downloadContainerWrapper .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    color: #495057;
}
.downloadContainerWrapper .ant-modal-close {
    top: 18px;
    right: 24px;
}
.downloadContainerWrapper .ant-modal-close-x {
    width: 14px;
    height: 14px;
    line-height: 14px;
}
.downloadContainerWrapper .ant-modal-body {
    padding: 0 24px 0 23px;
    font-size: 15px;
    font-weight: 500;
    color: #495057;
}
.downloadContainerWrapper .rangePickerWrapper {
    width: 100%;
}
.downloadContainerWrapper .ovalButtonWrapper {
    width: 460px;
}
.downloadContainerWrapper .ant-modal-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-top: 0;
    padding: 42px 23px 35px;
}
.downloadContainerWrapper .modalWithLoadingIcon {
    width: 42px;
    height: 42px;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
}
.downloadContainerWrapper .loadingWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 90px 0;
}
.downloadContainerWrapper .loadingWrapper img {
    margin-bottom: 24px;
}
.downloadContainerWrapper .loadingWrapper .loadingMsg {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.73px;
    text-align: center;
    color: #495057;
}
.downloadContainerWrapper .loadingWrapper .loadingMsg span{
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.61px;
    text-align: center;
    color: #495057;
}
.downloadContainerWrapper .downloadContentWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 21px;
}
.downloadContainerWrapper .datePickerWrapper {
    width: 300px;
}
.downloadContainerWrapper .ant-select-item {
    line-height: 30px;
}

.downloadContainerWrapper .padding-horizonatal-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.downloadContainerWrapper .selectWrapper {
    min-width: 200px !important;
}

.orderManagementWrapper {
    width: 100%;
    height: 100%;
    background-color: #f8f8fb;
    padding: 26px;
}
.orderManagementFilterWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
}
.ant-popover-inner-content {
    padding: 0;
}
.ant-popover-arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    right: 61px !important;
}
.orderManagementDotsWrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}
.orderManagementDots {
    display: -webkit-flex;
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.actionGroupPopOver {
    z-index: 1;
}
.listItemEllipsis p {
    position: relative;
    overflow: hidden;
    display: inline-block;
    word-wrap: break-word;
}

.orderManagementFilterWrapper .rangePickerWrapper {
    width: 65%;
    background-color: white;
    box-shadow: none;
    border-radius: 19px !important;
    border: 1px solid #ffffff;
}

.orderManagementFilterWrapper .rangePickerWrapper input {
    font-weight: 600;
    color: #73798e;
}
.orderDetailWrapper {
    width: 100%;
    height: auto;
    background-color: #f8f8fb;
    padding: 0 25px;
}
.orderDetailContent {
    background-color: #fff;
    padding-bottom: 46px;
}
.orderDetailContent .ant-descriptions-row .ant-descriptions-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #dde1e8;
}
.orderDetailContent .ant-descriptions-row .ant-descriptions-item.descriptionRow {
    width: 100%;
    border-bottom: 1px solid #dde1e8;
    padding-right: 30px;
}
.orderDetailContent .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.53px;
    color: #495057;
    width: 270px;
    padding-left: 26px;
}
.orderDetailContent .ant-descriptions-item-label::after {
    display: none;
}
.orderDetailContent .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.53px;
    color: #495057;
}
.orderDetailContent .ant-descriptions-item-content > div {
    margin-bottom: 6px;
}
.orderDetailContent .ant-descriptions-item-content > div:last-child {
    margin-bottom: 0;
}
.orderDetailContent .permissionItemIndex {
    margin-right: 15px;
}
.orderDetailContent .listItemIndex {
    margin-right: 5px;
}
.orderDetailWrapper .customCollapseWrapper {
    width: 500px;
    padding-top: 33px;
    padding-left: 38px;
    margin-bottom: 46px;
}
.orderDetailWrapper .ant-collapse-header {
    background-color: #dde1e8;
    font-size: 13px;
    font-weight: 600;
    color: #495057;
    padding: 8px 40px 8px 25px !important;
}
.orderDetailWrapper .ant-collapse-header img {
    width: 20px;
}
.orderDetailWrapper .ant-collapse-content-box {
    padding: 0 0 40px !important;
}
.orderDetailWrapper .detailsAttachmentWrapper {
    position: relative;
    display: -webkit-flex;
    display: flex;
}
.orderDetailWrapper .referenceDetailsAttachment {
    width: 105px;
    height: 156px;
}
.orderDetailWrapper .detailsAttachmentWrapper .zoomAttachment {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}
.zoomAttachment img {
    width: 42px;
    height: 42px;
}
.orderDetailWrapper .tableCardWrapper {
    box-shadow: none;
    margin-bottom: 41px;
}
.orderDetailWrapper .tableCardWrapper .ant-card-body {
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 20px;
}
.orderDetailWrapper .itemHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 200px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.61px;
    color: #0063a0;
    border-bottom: solid 2px #0063a0;
    margin-left: 19px;
}
.testManagementWrapper {
    width: 100%;
    height: 100%;
    background-color: #f8f8fb;
    padding: 26px;
}

.testManagementHeader {
    margin-bottom: 25px;
}

.testManagementDotsWrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}

.testManagementDots {
    display: -webkit-flex;
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.testDetailWrapper {
    width: 100%;
    height: auto;
    background-color: #f8f8fb;
    padding: 0 25px;
}
.testDetailWrapper .breadCrumbsComponentWrapper {
    padding: 31px 0 20px;
}
.testDetailContent {
    padding-left: 38px;
    background-color: #fff;
    padding-bottom: 106px;
}
.testDetailContent .ant-descriptions-header {
    margin-bottom: 0;
}
.testDetailContent .ant-descriptions-header .ant-descriptions-title {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding-top: 27px;
    padding-bottom: 22px;
    border-bottom: 1px solid #dde1e8;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    color: #495057;
}
.testDetailContent .ant-descriptions-row .ant-descriptions-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding: 21px 0;
    border-bottom: 1px solid #dde1e8;
}
.testDetailContent .ant-descriptions-row .ant-descriptions-item.descriptionRow {
    width: 800px;
    border-bottom: 1px solid #dde1e8;
    padding-right: 30px;
}
.testDetailContent .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.53px;
    color: #495057;
    width: 270px;
    padding-left: 26px;
}
.testDetailContent .ant-descriptions-item-label::after {
    display: none;
}
.testDetailContent .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.53px;
    color: #495057;
}
.testDetailContent .ant-descriptions-item-content > div {
    margin-bottom: 6px;
}
.testDetailContent .ant-descriptions-item-content > div:last-child {
    margin-bottom: 0px;
}
.testDetailContent .permissionItemIndex {
    margin-right: 15px;
}
.testDetailContent .listItemIndex {
    margin-right: 5px;
}
.testDetailContent .preparationItem {
    margin-left: 24px;
}

.panelManagementWrapper {
    width: 100%;
    height: 100%;
    background-color: #f8f8fb;
    padding: 26px;
}

.panelManagemenHeader {
    margin-bottom: 25px;
}

.panelManagementDotsWrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}

.panelManagementDots {
    display: -webkit-flex;
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.panelDetailWrapper {
    width: 100%;
    height: auto;
    background-color: #f8f8fb;
    padding: 0 25px;
}
.panelDetailWrapper .breadCrumbsComponentWrapper {
    padding: 31px 0 20px;
}
.panelDetailContent {
    padding-left: 38px;
    background-color: #fff;
    padding-bottom: 106px;
}
.panelDetailContent .ant-descriptions-header {
    margin-bottom: 0;
}
.panelDetailContent .ant-descriptions-header .ant-descriptions-title {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding-top: 27px;
    padding-bottom: 22px;
    border-bottom: 1px solid #dde1e8;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    color: #495057;
}
.panelDetailContent .ant-descriptions-row .ant-descriptions-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding: 21px 0;
    border-bottom: 1px solid #dde1e8;
}
.panelDetailContent .ant-descriptions-row .ant-descriptions-item.descriptionRow {
    width: 800px;
    border-bottom: 1px solid #dde1e8;
    padding-right: 30px;
}
.panelDetailContent .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.53px;
    color: #495057;
    width: 270px;
    padding-left: 26px;
}
.panelDetailContent .ant-descriptions-item-label::after {
    display: none;
}
.panelDetailContent .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.53px;
    color: #495057;
}
.panelDetailContent .ant-descriptions-item-content > div {
    margin-bottom: 6px;
}
.panelDetailContent .ant-descriptions-item-content > div:last-child {
    margin-bottom: 0px;
}
.panelDetailContent .permissionItemIndex {
    margin-right: 15px;
}
.panelDetailContent .listItemIndex {
    margin-right: 5px;
}
.panelDetailContent .preparationItem {
    margin-left: 24px;
}

.promoManagementWrapper {
    width: 100%;
    height: 100%;
    background-color: #f8f8fb;
    padding: 26px;
}
.promoManagementFilterWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
}
.promoManagementFilterLeft {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}
.ant-popover-inner-content {
    padding: 0;
}
.ant-popover-arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    right: 61px !important;
}
.promoManagementDotsWrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}
.promoManagementDots {
    display: -webkit-flex;
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.actionGroupPopOver {
    z-index: 1;
}

.promoDetailWrapper {
    width: 100%;
    height: auto;
    background-color: #f8f8fb;
    padding: 0 25px;
}
.promoDetailWrapper .breadCrumbsComponentWrapper {
    padding: 31px 0 20px;
}
.promoDetailContent {
    background-color: #fff;
    padding-left: 38px;
    padding-right: 38px;
    padding-bottom: 106px;
}
.promoDetailContent .ant-descriptions-header {
    margin-bottom: 0;
}
.promoDetailContent .ant-descriptions-header .ant-descriptions-title {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding-top: 27px;
    padding-bottom: 22px;
    border-bottom: 1px solid #dde1e8;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    color: #495057;
}
.promoDetailContent .ant-descriptions-row .ant-descriptions-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 500px;
    padding: 21px 0 0 0;
}
.promoDetailContent .ant-descriptions-row .ant-descriptions-item.descriptionRow {
    width: 100%;
}
.promoDetailContent .ant-descriptions-row .ant-descriptions-item.descriptionRowWithBorder {
    width: 100%;
    border-bottom: 1px solid #dde1e8;
}
.promoDetailContent .ant-descriptions-row .ant-descriptions-item .descDivider {
    margin-top: 21px;
    width: 230px;
    border-bottom: 1px solid #dde1e8;
}
.promoDetailContent .ant-descriptions-item-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.53px;
    color: #495057;
    width: 270px;
    padding-left: 26px;
}
.promoDetailContent .ant-descriptions-item-label::after {
    display: none;
}
.promoDetailContent .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.53px;
    color: #495057;
}
.promoDetailContent .ant-descriptions-item-content .listWrapper > div {
    margin-bottom: 8px;
}
.promoDetailContent .ant-descriptions-item-content .listWrapper > div:last-child {
    margin-bottom: 0;
}
.promoDetailContent .listItemIndex {
    margin-right: 15px;
}
.promoDetailContent .promoDetailImg {
    width: 230px;
    height: 156px;
}
.promoDetailContent .branchListScroll {
    max-height: 415px;
    overflow-y: scroll;
    padding-right: 15px;
}
.promoDetailContent .branchListWrapper > div{
    margin-bottom: 8px;
}
.promoDetailContent .branchListWrapper > div:last-child {
    margin-bottom: 0;
}
.promoDetailContent .contractListWrapper .contractListHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 200px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.61px;
    color: #0063a0;
    border-bottom: solid 2px #0063a0;
    margin-left: 19px;
}
.promoDetailContent .contractListWrapper .tableCardWrapper .rowWhite {
    box-shadow: none;
}

.promoDetailContent .tableCardWrapper .ant-table-tbody > tr > td {
    vertical-align: top;
}

.promoDetailContent .tableCardWrapper .ant-table-tbody > tr > td:last-child {
    padding: 19px 16px;
}

.ageInputWrapper .ant-input {
    width: 159px;
}

.ageInputWrapper .ant-select {
    width: 100px;
    margin-left: 20px;
}

.panelFormViewWrapper {
    padding: 19px 29px;
}

.panelFormViewWrapper .ant-card-body {
    padding: 0;
}

.panelFormViewWrapper .infoRow {
    width: 501px;
}

.panelFormViewWrapper .infoRow .infoCol {
    border-bottom: 1px solid #dde1e8;
    padding: 20px 0;
}

.panelFormViewWrapper .ant-form {
    width: 501px;
    margin-top: 22px;
}

.panelFormViewWrapper .infoRow .infoCol .infoLabel {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
};

.panelFormViewWrapper .infoRow .infoCol .infoValue {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
};

.panelFormViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.panelFormViewAction {
    display: -webkit-flex;
    display: flex;
    margin-top: 14px;
}

.panelFormViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}


.productTypeItemWrapper .ant-checkbox-wrapper .ant-checkbox + span {
    padding: 0 0 0 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #73798e;
}

.testFormViewWrapper {
    padding: 19px 29px;
}

.testFormViewWrapper .ant-card-body {
    padding: 0;
}

.testFormViewWrapper .infoRow {
    width: 501px;
}

.testFormViewWrapper .infoRow .infoCol {
    border-bottom: 1px solid #dde1e8;
    padding: 20px 0;
}

.testFormViewWrapper .ant-form {
    width: 501px;
    margin-top: 22px;
}

.testFormViewWrapper .infoRow .infoCol .infoLabel {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
};

.testFormViewWrapper .infoRow .infoCol .infoValue {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
    color: #495057;
};

.testFormViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.testFormViewAction {
    display: -webkit-flex;
    display: flex;
    margin-top: 14px;
}

.testFormViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}


.imageUpload {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.promoImageWrapper {
    width: 231px;
    height: 156px;
    position: relative;
}

.promoImage {
    width: 231px;
    height: 156px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.buttonUploadWrapper {
    width: 231px;
    height: 156px;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.buttonUpload {
    width: 150px;
    border-radius: 3px;
    z-index: 2;
    color: #0063a0;
    font-weight: 600;
}

.imageEditWrapper {
    height: 156px;
    margin-left: 60px;
    position: relative;
}

.imageInfoWrapper {
    height: 156px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: 0;
}

.imageInfo {
    width: 276px;
    color: #a4a7ab;
}

.ant-upload-picture-card-wrapper {
    width: auto;
}

.ant-upload.ant-upload-select-picture-card {
    width: 231px;
    height: 156px;
}
/* This used to override body style from tinymce skin */
body {
    margin: 0;
    font-family: 'Poppins';
}

.promoFormViewWrapper {
    padding: 11px 9px;
}

.promoFormViewWrapper .ant-form-vertical .ant-form-item {
    width: 700px;
}

.promoFormViewWrapper .ant-input[disabled] {
    color: #495057;
    background-color: white;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.promoFormViewAction {
    width: 700px;
    display: -webkit-flex;
    display: flex;
    margin-top: 14px;
}

.promoFormViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.listItemEllipsis p {
    position: relative;
    overflow: hidden;
    display: inline-block;
    word-wrap: break-word;
}

.promoFormViewWrapper .tableCardWrapper .ant-card-body {
    padding: 0 0 86px 0;
}

.promoFormViewWrapper .tableCardWrapper .ant-table-tbody > tr > td {
    vertical-align: top;
}

.promoFormViewWrapper .tableCardWrapper .ant-table-tbody > tr > td:last-child {
    vertical-align: middle;
}

.promoFormViewWrapper .tableCardWrapper .ant-table-row {
    box-shadow: none;
}

.promoFormViewWrapper .contractListWrapper .contractListHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 200px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.61px;
    color: #0063a0;
    border-bottom: solid 2px #0063a0;
}
.promoFormViewWrapper .contractListWrapper .tableCardWrapper .rowWhite {
    box-shadow: none;
}

.branchManagementWrapper {
  width: 100%;
  height: 100%;
  background-color: #f8f8fb;
  padding: 26px;
}

.branchManagementFilterWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 25px;
}

.branchManagementDotsWrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.branchManagementDots {
  display: -webkit-flex;
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.availableTestList {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 20px 0;
}

.availableTestList .listHeader {
  padding: 0 22px 20px;
}

.availableTestList .listContent {
  max-height: 580px;
  overflow-y: auto;
  padding-left: 22px;
  margin-right: 22px;
}

.availableTestList .listContent > .listItem {
  margin-bottom: 14px;
}
.selectedTestList {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 20px 0;
}

.selectedTestList .listHeader {
  padding: 0 22px;
}

.selectedTestList .listContent {
  max-height: 580px;
  overflow-y: auto;
  padding-left: 22px;
  margin-right: 22px;
}

.selectedTestList .listContent > .listItem {
  margin-bottom: 14px;
}
.branchTestListInput {
  display: -webkit-flex;
  display: flex;
}
.branchTestListInput .moveItemButtons {
  padding: 0 40px; 
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.branchTestListInput .moveItemButtons button {
  border-radius: 50%;
  width: 58px;
  height: 58px;
  border: 0px;
  background: #d8d8d8;
  margin-bottom: 16px;
  color: #000;
}
.branchTestListInput .moveItemButtons button:disabled {
  opacity: 0.3;
}
.branchTestListInput .testAvailableWrapper,
.branchTestListInput .testSelectedWrapper {
  width: 363px;
}
.branchForm {
  background-color: #fff;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 35px;
}
.branchForm .ant-descriptions-header {
  margin-bottom: 0;
}
.branchForm .ant-descriptions-header .ant-descriptions-title {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  width: 500px;
  padding-top: 27px;
  padding-bottom: 22px;
  border-bottom: 1px solid #dde1e8;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.53px;
  color: #495057;
}
.branchForm .ant-descriptions-row .ant-descriptions-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  width: 500px;
  padding: 21px 0;
  border-bottom: 1px solid #dde1e8;
}
.branchForm .ant-descriptions-row .ant-descriptions-item.descriptionRow {
  width: 100%;
  border-bottom: 1px solid #dde1e8;
}
.branchForm .ant-descriptions-item-label {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.53px;
  color: #495057;
  width: 270px;
  padding-left: 26px;
}
.branchForm .ant-descriptions-item-label::after {
  display: none;
}
.branchForm .ant-descriptions-item-content {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.53px;
  color: #495057;
}
.branchForm .ant-descriptions-item-content .listWrapper > div {
  margin-bottom: 8px;
}
.branchForm .ant-descriptions-item-content .listWrapper > div:last-child {
  margin-bottom: 0;
}
.branchForm .listItemIndex {
  margin-right: 15px;
}
.branchForm .promoDetailImg {
  width: 230px;
  height: 156px;
}
.branchForm .branchListScroll {
  max-height: 415px;
  overflow-y: scroll;
  padding-right: 15px;
}
.branchForm .branchListWrapper > div{
  margin-bottom: 8px;
}
.branchForm .branchListWrapper > div:last-child {
  margin-bottom: 0;
}
.branchForm .contractListWrapper .contractListHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 200px;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.61px;
  color: #0063a0;
  border-bottom: solid 2px #0063a0;
  margin-left: 19px;
}
.branchForm .contractListWrapper .tableCardWrapper .rowWhite {
  box-shadow: none;
}
.branchForm .tableCardWrapper .ant-table-tbody > tr > td {
  vertical-align: top;
}
.branchForm .tableCardWrapper .ant-table-tbody > tr > td:last-child {
  padding: 19px 16px;
}
.branchForm .ant-descriptions-item.sectionHeader {
  padding: 8px 25px;
  color: #495057;
  background-color: #dde1e8;
  display: block;
  margin-top: 28px;
}
.branchForm .ant-descriptions-item.sectionHeader .ant-descriptions-item-content {
  font-weight: bold;
}
.branchForm .ant-descriptions-item-container {
  width: 100%;
}
.branchForm .serviceTestListContent {
  width: 100%;
  max-height: 371px;
  overflow-y: auto;
  padding-left: 8px;
}
.branchForm .serviceListContent {
  width: 100%;
  max-height: 371px;
  overflow-y: auto;  
  padding-left: 8px;
}
.branchForm .serviceListContent {
  padding-left: 8px;
}
.branchForm .serviceListContent .serviceList {
  padding-left: 14px;
}
.branchForm .serviceList li {
  margin-bottom: 8px;
}
.branchForm .service-group {
  display: inline-block;
  margin-bottom: 8px;
}
.branchForm .branchEditViewAction {
  display: -webkit-flex;
  display: flex;
  margin-top: 38px;
  width: 500px;
}
.branchForm .branchEditViewAction .ant-btn {
  width: 170px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
}
.branchForm .ant-descriptions-row .ant-descriptions-item.availableTestsRow {
  border-bottom: 0;
}
.branchForm .ant-descriptions-row .ant-descriptions-item.branchTestListWrapper {
  padding-top: 0;
  padding-left: 25px;
  border-bottom: 0;
}
.branchForm .ant-descriptions-row .ant-descriptions-item .operationalTimes {
  display: table;
}
.branchForm .ant-descriptions-row .ant-descriptions-item .operationalTimesItem {
  display: table-row;
}
.branchForm .ant-descriptions-row .ant-descriptions-item .operationalTimeDay,
.branchForm .ant-descriptions-row .ant-descriptions-item .operationalTimeHour {
  display: table-cell;
}

.branchDetailWrapper {
  width: 100%;
  height: auto;
  background-color: #f8f8fb;
  padding: 0 25px;
}
.branchDetailWrapper .breadCrumbsComponentWrapper {
  padding: 31px 0 20px;
}

.branchEditWrapper {
  width: 100%;
  height: auto;
  background-color: #f8f8fb;
  padding: 0 25px;
}
.branchEditWrapper .breadCrumbsComponentWrapper {
  padding: 31px 0 20px;
}
.branchEditWrapper .branchForm .ant-descriptions-header .ant-descriptions-title {
  border-bottom: 0px;
}
.createPasswordViewWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
}
.createPasswordViewWrapper .ant-form-item {
  margin-bottom: 0;
}
.createPasswordViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.createPasswordViewWrapper .ant-form-item-label {
  padding: 0 0 10px;
}
.createPasswordViewWrapper .ant-input {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 9px 11px;
  color: #495057;
}
.createPasswordViewWrapper .ant-input-affix-wrapper {
  padding: 9px 11px;
}
.createPasswordViewWrapper .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
}
.createPasswordViewWrapper .ant-form-item-explain, .ant-form-item-extra {
  margin-top: 5px;
  font-size: 12px;
  transition: none;
  color: #e71c1c;
}
.createPasswordViewWrapper .ant-form-item-control {
  width: 100%;
  max-width: 100%;
  min-height: 75px;
}
.createPasswordViewWrapper .ant-form {
  width: 500px;
  background-color: #ffffff;
}
.createPasswordViewHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffdd00;
  width: 100%;
  height: 80px;
  margin-bottom: 35px;
}
.createPasswordViewHeader img {
  width: 98px;
  height: 35px;
}
.createPasswordViewFormContent {
  width: 100%;
  padding: 0 50px;
}
.createPasswordViewFormTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #495057;
  margin-bottom: 5px;
}
.createPasswordViewFormDesc {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #74788d;
  margin-bottom: 35px;
}
.createPasswordViewButtonWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-bottom: 63px;
}
.blue-tooltip.password-tooltip .ant-tooltip-inner {
  width: 280px;
}
.createPasswordViewWrapper .ant-form-item.newPasswordFormItem {
  position: relative;
}
.createPasswordViewWrapper .tooltipTriggerBtn {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
}
.settingsManagementWrapper {
  width: 100%;
  height: auto;
  background-color: #f8f8fb;
  padding: 0 25px;
}
.settingsManagementWrapper .breadCrumbsComponentWrapper {
  padding: 31px 0 20px;
}
.settingsDetailWrapper {
  padding: 25px 40px;
  background-color: #fff;
  padding-bottom: 106px;
}
.settingsDetailContent {
  width: 500px;
}
.settingsDetailContent .settingsDetailHeader {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
}
.settingsDetailContent .settingsDetailHeader .settingsDetailHeaderText {
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  letter-spacing: -0.53px;
}
.settingsOptionCardBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;
}
.settingsOptionCardBody .settingsOptionCardTitle {
  font-weight: bold;
  margin-left: 26px;
  letter-spacing: -0.53px;
}
.settingsOptionCardBody .settingsOptionCardLink {
  font-weight: 500;
  color: #3d7dc3;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: -0.53px;
}
.settingsSwitchButton {
  position: relative;
  margin: auto 0;
}
.toggleEnable1 {
  width: 21px;
  height: 12px;
  margin: 0 6px 0 0;
  background-color: #98c8e6;
  float: left;
  z-index: 2;
  position: relative;
  border-radius: 30px;
}
.toggleEnable2 {
  width: 12px;
  height: 12px;
  margin: 0 0 0 8px;
  background-color: #0063a0;
  position: relative;
  z-index: 3;
  border-radius: 30px;
}
.toggleDisable1 {
  width: 21px;
  height: 12px;
  margin: 0 6px 0 0;
  background-color: #ccc;
  float: left;
  z-index: 2;
  position: relative;
  border-radius: 30px;
}
.toggleDisable2 {
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
  background-color: #8f8d8d;
  position: relative;
  z-index: 3;
  border-radius: 30px;
}
.pointer {
  cursor: pointer;
}
.disabledText {
  opacity: 0.5;
  cursor: default;
}
.settingsViewAction {
  width: 700px;
  display: -webkit-flex;
  display: flex;
  margin-top: 14px;
}

.settingsViewAction .ant-btn {
  width: 170px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
}

