.accountActivationViewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
}
.accountActivationViewWrapper .ant-form-item {
  margin-bottom: 0;
}
.accountActivationViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.accountActivationViewWrapper .ant-form-item-label {
  padding: 0 0 10px;
}
.accountActivationViewWrapper .ant-input {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 9px 11px;
  color: #495057;
}
.accountActivationViewWrapper .ant-input-affix-wrapper {
  padding: 9px 11px;
}
.accountActivationViewWrapper .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
}
.accountActivationViewWrapper .ant-form-item-explain, .ant-form-item-extra {
  margin-top: 5px;
  font-size: 12px;
  transition: none;
  color: #e71c1c;
}
.accountActivationViewWrapper .ant-form-item-control {
  width: 100%;
  max-width: 100%;
  min-height: 75px;
}
.accountActivationViewWrapper .ant-form {
  width: 500px;
  background-color: #ffffff;
}
.accountActivationViewHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffdd00;
  width: 100%;
  height: 80px;
  margin-bottom: 35px;
}
.accountActivationViewHeader img {
  width: 98px;
  height: 35px;
}
.accountActivationViewFormContent {
  width: 100%;
  padding: 0 50px;
}
.accountActivationViewFormTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #495057;
  margin-bottom: 5px;
}
.accountActivationViewFormDesc {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #74788d;
  margin-bottom: 35px;
}
.activationViewButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 63px;
}

/* Custom tooltip styles */

.blue-tooltip.pin-tooltip {
  max-width: 280px;
}
.blue-tooltip .ant-tooltip-inner {
  background: #f1faff;
  color: #0063a0;
  border: 1px solid #0063a0;
  border-radius: 4px;
  z-index: 10;
}
.blue-tooltip .ant-tooltip-arrow {
  width: 17.071068px;
  height: 13.071068px;
  background: transparent;
}
.blue-tooltip .ant-tooltip-arrow-content {
  background: #f1faff;
  border: 1px solid #0063a0;
  width: 13px;
  height: 13px;
}
.blue-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -4.071068px;
}

.blue-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-arrow, 
.blue-tooltip.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -4.071068px;
}
