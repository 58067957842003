.ant-form .ant-tree-switcher {
    display: none;
}

.ant-form .ant-tree-title {
    margin-left: 14px;
    padding: 0;
}

.ant-form .ant-tree-indent-unit {
    width: 48px;
}

.ant-form .ant-tree-checkbox {
    margin: 4px 0px 0 0;
}
