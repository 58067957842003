.changePasswordViewWrapper {
    border-radius: 50px;
}

.changePasswordViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.changePasswordViewTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: 'normal';
    font-style: 'normal';
    line-height: 'normal';
    letter-spacing: -0.65;
    color: '#495057';
    margin-bottom: 23px;
}

.changePasswordViewAction {
    display: flex;
    margin-top: 50px;
}

.changePasswordViewAction .ant-btn {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}

.changePasswordViewAction .ant-btn-primary {
    width: 170px;
}
