.adminFormViewWrapper {
    padding: 11px 9px;
}

.adminFormViewWrapper .ant-form {
    width: 501px;
}

.adminFormViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.adminEditViewAction {
    display: flex;
    margin-top: 14px;
}

.adminEditViewAction .ant-btn {
    width: 170px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.53px;
}
