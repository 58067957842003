.loginViewWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 100vw;
    height: 100vh;
}
.loginViewWrapper .ant-form-item {
    margin-bottom: 0;
}
.loginViewWrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.loginViewWrapper .ant-form-item-label {
    padding: 0 0 10px;
}
.loginViewWrapper .ant-input {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    padding: 9px 11px;
    color: #495057;
}
.loginViewWrapper .ant-input-affix-wrapper {
    padding: 9px 11px;
}
.loginViewWrapper .ant-form-item-label > label {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    color: #495057;
}
.loginViewWrapper .ant-form-item-explain, .ant-form-item-extra {
    margin-top: 5px;
    font-size: 12px;
    transition: none;
    color: #e71c1c;
}
.loginViewWrapper .ant-form-item-control {
    width: 100%;
    max-width: 100%;
    min-height: 75px;
}
.loginViewWrapper .ant-form {
    width: 500px;
    background-color: #ffffff;
}
.loginViewHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffdd00;
    width: 100%;
    height: 80px;
    margin-bottom: 35px;
}
.loginViewHeader img {
    width: 98px;
    height: 35px;
}
.loginViewFormContent {
    width: 100%;
    padding: 0 50px;
}
.loginViewFormTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #495057;
    margin-bottom: 5px;
}
.loginViewFormDesc {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #74788d;
    margin-bottom: 35px;
}
.loginViewButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 63px;
}
.loginViewButtonWrapper .activation {
    color: #74788d;
}
.loginViewButtonWrapper .activation .activationLink {
    font-weight: bold;
    color: #0063a0;
}
