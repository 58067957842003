.availableTestList {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 20px 0;
}

.availableTestList .listHeader {
  padding: 0 22px 20px;
}

.availableTestList .listContent {
  max-height: 580px;
  overflow-y: auto;
  padding-left: 22px;
  margin-right: 22px;
}

.availableTestList .listContent > .listItem {
  margin-bottom: 14px;
}